export const ChartData = [
    {
                      
            
    }      
]

export const PickerData = [
    {
        id: "from",
        htmlFor: "start",
        type: "month",         
        name: "trip-start",
        //value:"",
        min: "2022-01",
        max: "2022-12"             
            
    },
    {
        id: "to", 
        htmlFor: "finish", 
        type: "month",         
        name:"trip-finish",
        //value:"",
        min: "2022-01", 
        max: "2022-12"           
    }    
]
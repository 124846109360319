import React from "react"
import LineChart from "./components/LineChart"

export default function App() {        
        
    return (
        <div className="container">                                    
            <LineChart />
        </div>        
    )
}